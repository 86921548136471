<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Payment Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Transaction Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Payment Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i
              class="fas fa-download"
              title="Download"
              @click="download"
            ></i>
          </div>
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select
            v-model="params.pageIndex"
            @change="searchFilter"
            class="mg-t-5 mr-2"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select
            class="mg-t-5 mr-2"
            v-model="params.session_year"
            @change="searchFilter"
          >
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}/{{ parseInt(year.substring(year.length - 2)) + 1 }}
            </option>
          </select>
          <select
            v-model="params.branch"
            class="filter-input mg-t-5 mr-2"
            @change="searchFilter"
          >
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in paymentDataAttrbutes.branchs"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <select
            v-model="params.type"
            class="filter-input mg-t-5 mr-2"
            @change="searchFilter"
          >
            <option value="">All Transaction</option>
            <option value="0">Incoming</option>
            <option value="1">Outgoing</option>
          </select>
          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
          <!-- <select v-model="params.payment_method" class="filter-input ml-2">
            <option value="">All Method</option>
            <option
              v-for="(method, index) in dataLists9"
              :key="index"
              :value="method.id"
            >
              {{ method.title }}
            </option>
          </select> -->

          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="params.from"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              @change="searchFilter"
            />
            <label class="filter-lavel mb-0 ml-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="params.to"
              placeholder="To"
              class="mg-t-5 filter-input"
              @change="searchFilter"
            />
          </div>
          <input
            type="text"
            v-model="params.searched"
            class="filter-input mg-t-5 mr-2"
            placeholder="Search..."
            @keyup="searchKeyword"
          />
          <div class="float-right mr-3 mg-t-5">
            <select
              class="filter-input ml-2 float-right"
              v-model="route"
              @change="filterReportType"
            >
              <option value="/general-report/payment-report">
                Transaction Wise
              </option>
              <option value="/general-report/payment-report/method-wise">
                Method Wise
              </option>
              <option value="/general-report/payment-report/branch-wise">
                Branch Wise
              </option>
              <option value="/general-report/payment-report/day-wise">
                Day Wise
              </option>
              <option value="/general-report/payment-report/month-wise">
                Month Wise
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-theam-secondary table-search-head">
      <div>
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Opening Balance:
          {{ parseDigitForSlip(paymentDataAttrbutes.total_opening) }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Closing Balance:
          {{ parseDigitForSlip(paymentDataAttrbutes.total_closing) }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Incoming :
          {{ parseDigitForSlip(paymentDataAttrbutes.total_incoming) }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Outgoing :
          {{ parseDigitForSlip(paymentDataAttrbutes.total_outgoing) }}</span
        >
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto" >
        <table class="table table2 table-hover" id="tableData" ref="paymentreport">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wwd-10p">Date</th>
              <th class="wd-30p">Description</th>
              <th class="wd-10p">Status</th>
              <th class="wd-15p">Payment Type</th>
              <th class="wd-15p">Payment Type</th>
              <th class="wd-15p">Amount</th>
            </tr>
          </thead>
          <tbody v-if="!loading && transactionWiseDatas.length > 0">
            <tr v-for="(payment, index) in transactionWiseDatas" :key="index">
              <td>{{ pageSerialNo + index }}.</td>
              <td>{{ payment.date }}</td>
              <td>
                {{ payment.description }}
              </td>
              <td>
                <span class="badge badge-primary" v-if="payment.status == 1"
                  >Updated</span
                >
                <span class="badge badge-danger" v-else-if="payment.status == 2"
                  >Canceled</span
                >
                <span
                  class="badge badge-warning"
                  v-else-if="payment.status == 3"
                  >Returned</span
                >
                <span class="badge badge-info" v-else-if="payment.status == 4"
                  >Balanced</span
                >
                <span class="badge badge-success" v-else>Created</span>
              </td>
              <td>
                {{ payment.payment_method.title }}
                <span v-if="payment.bank">- {{ payment.bank.bank }}</span>
              </td>
              <td>
                <span
                  class="badge badge-success"
                  v-if="payment.transaction_type == 0"
                  >Incoming</span
                ><span class="badge badge-danger" v-else>Outgoing</span>
              </td>
              <td>{{ parseDigitForSlip(payment.amount) }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td class="text-center" colspan="11">{{ error_message }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td class="text-center" colspan="11">Loading...</td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && transactionWiseDatas.length == 0">
            <tr>
              <td class="text-center" colspan="11">No Records Found...</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Paginate from "vuejs-paginate";
import SearchDayMonthComponent  from "../../../../shared/SearchDayMonthComponent";
import { mapGetters } from "vuex";
import Services from "./Services/Services";
import _ from 'lodash';

export default {
  components: {
    Paginate,SearchDayMonthComponent
  },
  data() {
    return {
      sessionYears: ["2021"],
      route: this.$route.path,
      params:{
        pageIndex:20,
        branch:'',
        bank:'',
        type:"",
        from:'',
        session_year:2021,
        searched:'',
        to:'',
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        offset:0,
      },
      excel_params:{
        pageIndex:20,
        branch:'',
        bank:'',
        type:"",
        from:'',
        session_year:2021,
        searched:'',
        to:'',
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        offset:0,
      },
      page:1,
      pageSerialNo:1,
      error:false,
      error_message:'',
      loading:false,
      totalPageCount:'',
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists1",
      "dataLists2",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("paymentReport",['paymentDataAttrbutes','transactionWiseDatas'])
  },
  methods: {
    monthDayClicked(value){
      if(value){
        this.params.day = value.day;
        this.params.month = value.month;
        this.params.year = value.year
      }
      //make api call
      this.getFilteredData();
    },
     clickCallback: function(page) {
      if(page == 1){

        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.pageIndex) + 1 ;
      }
      this.params.offset = (page-1) * this.params.pageIndex;
      this.getData();
    },
    print(){
      window.print();
    },
    filterReportType() {
      if(this.$route.path!=this.route) {
        this.$router.push(this.route);
      }
    },
    searchFilter(){
        this.page=1;
        this.pageSerialNo=1;
        this.params.offset = 0;
        this.getFilteredDataWithoutDates();
    },
   searchKeyword:_.debounce(function(){
        this.page=1;
        this.pageSerialNo=1;
        this.params.offset = 0;
        this.getFilteredDataWithoutDates();
    },1000),
     getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.params.pageIndex,
        branch: this.params.branch,
        today: this.params.day,
        month: this.params.month + 1,
        type:this.params.type,
        year: this.params.year,
        from: this.params.from,
        to: this.params.to,
        searched: this.params.searched,
        offset: this.params.offset,
      };
      this.excel_params = {
        pageIndex: this.params.pageIndex,
        branch: this.params.branch,
        today: this.params.day,
        month: this.params.month + 1,
        type:this.params.type,
        year: this.params.year,
        from: this.params.from,
        to: this.params.to,
        searched: this.params.searched,
        offset: this.params.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.params.pageIndex,
        type:this.params.type,
        branch: this.params.branch,
        today: "",
        month: "",
        year: this.params.year,
        from: this.params.from,
        to: this.params.to,
        searched: this.params.searched,
        offset: this.params.offset,
      };
      this.excel_params = {
        pageIndex: this.params.pageIndex,
        type:this.params.type,
        branch: this.params.branch,
        today: "",
        month: "",
        year: this.params.year,
        from: this.params.from,
        to: this.params.to,
        searched: this.params.searched,
        offset: this.params.offset,
      };
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      this.loading = true;
      Services.getTransactionWiseReport(params)
        .then((res) => {
          this.$store.commit("paymentReport/setTransactionWiseDatas",res.data.data.data);
          this.$store.commit("paymentReport/setPaymentDataAttrbutes",res.data.data.attributes);
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          this.error = true;
          if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }
          this.$store.commit("loanReports/setTransactionWiseDatas", []);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      this.getFilteredDataWithoutDates();
    },
    download(){
      Services.getTransactionWiseExcel(this.excel_params).then((response)=>{
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
        const elink = document.createElement("a");
            elink.download = 'payment-transaction-wise-report.xlsx';
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL (elink.href); // release the URL object
            document.body.removeChild(elink);
      }).catch(err=>{
        console.log(err);
      });
    },
  },
  beforeMount() {
    this.getData();
  },
};
</script>